import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import styles from "../services/services.module.css";
import ServicesModel from "../../components/models/ServicesModel";
import CardComponent from "../../components/CardComponent";
import { calculateTime } from "../../utils/dateUtils";
import { BsFillEyeFill } from "react-icons/bs";
import TransactionView from "./TransactionView";
import { FaUsers } from "react-icons/fa";
import { HiMiniBriefcase } from "react-icons/hi2";
import { FaSackDollar } from "react-icons/fa6";

const Transactions = () => {
  //////////////////////// dummy data /////////////////////////////////

  const items = [
    {
      client: "Danya Garcia",
      invoice: "LTS15E0IOD9",
      date: "2023-10-22T12:07:43.177Z",
      total: 250,
      status: "Complete",
    },
  ];

  const card = [
    {
      name: "Total Requests",
      icon: <FaUsers className="text-2xl w-full text-sw-100" />,
      count: 100,
    },
    {
      name: "Approved Requests",
      icon: <HiMiniBriefcase className="text-2xl w-full text-sw-100" />,
      count: 200,
    },
    {
      name: "Reject Requests",
      icon: <FaSackDollar className="text-2xl w-full text-sw-100" />,
      count: 201,
    },
  ];

  //////////////////////////////////////////////////////////////////////

  const [state, setState] = useState({
    toggle: false,
    selectedId: null,
  });

  const showTransactionModal = (index) => {
    setState({ ...state, toggle: true, selectedId: index });
  };

  const closeView = () => {
    setState({ ...state, toggle: false, selectedId: -1 });
  };

  useEffect(() => {}, [state]);

  return (
    <div className="p-1">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-2">
        {/* CARD 1 */}

        {card &&
          card.map((item, index) => (
            <div className="h-[160px] border shadow-md text-gray-700 hover:shadow-xl transition-all ease-in-out duration-300 rounded-md flex justify-between items-center space-x-4 px-2 py-2 cursor-pointer">
              <div className="flex flex-col space-y-4 h-full w-[calc(100%-30%)]">
                <div className="shadow-lg ring-1 flex flex-col justify-center items-center aspect-square ring-gray-100 px-2 rounded-lg w-[25%]">
                  {item.icon}
                </div>
                <div className=" h-full relative">
                  <div className="flex flex-col space-y-2 items-start absolute bottom-0">
                    <span className="text-md font-normal">{item?.name}</span>
                    <span className="text-lg font-bold">{item?.count}</span>
                  </div>
                </div>
              </div>
              <div className="w-[30%] h-full relative flex justify-center">
                <div className="border absolute border-gray-800 px-6 rounded-full bottom-2">
                  <span className="text-mg font-semibold text-gray-600">
                    20%
                  </span>
                </div>
              </div>
            </div>
          ))}

        {/* END CARD  */}
      </div>

      <div className="shadow-md border rounded-lg">
        <h1 className="text-lg font-semibold mb-3 px-2">Transactions</h1>

        <div className="shadow-md border">
          <div
            className={`h-[400px] overflow-y-auto cursor-pointer ${styles.editorContainer} relative`}
          >
            <table className="w-full text-sm text-left text-gray-500 relative">
              <thead className="text-xs text-white uppercase bg-sw-100/[0.8]">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Client
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Invoice
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {items &&
                  items.map((item, index) => (
                    <tr
                      className={`${
                        (index + 1) % 2 == 0 ? "bg-gray-100" : "bg-gray-50"
                      }`}
                      key={index + 1}
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {item?.client}
                      </th>
                      <td className="px-6 py-4">{item?.invoice}</td>
                      <td className="px-6 py-4">{calculateTime(item?.date)}</td>
                      <td className="px-6 py-4">Rs {item?.total}</td>
                      <td
                        className={`px-6 py-4 ${
                          item?.status.toLowerCase() === "complete"
                            ? "text-green"
                            : "text-red"
                        }`}
                      >
                        {item?.status}
                      </td>
                      <td class="px-6 py-4">
                        <button
                          onClick={() => showTransactionModal(index)}
                          class="font-medium"
                        >
                          <BsFillEyeFill className="text-md" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center py-2 px-4">
            <div className="h-12 w-[300px] relative flex justify-between">
              <div></div>
              <div>
                <ul className="flex items-center h-12 text-sm">
                  <li>
                    <a
                      href="#"
                      className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:text-gray-70"
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        className="w-2.5 h-2.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 1 1 5l4 4"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:text-gray-700 dark:hover:text-gray-700"
                    >
                      1
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:text-gray-700 dark:hover:text-gray-700"
                    >
                      2
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      aria-current="page"
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:text-gray-700 dark:hover:text-gray-700"
                    >
                      3
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:text-gray-700 dark:hover:text-gray-700"
                    >
                      4
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:text-gray-700 dark:hover:text-gray-700"
                    >
                      5
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:text-gray-700 dark:hover:text-gray-700"
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        className="w-2.5 h-2.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {state.toggle && (
        <TransactionView
          isToggle={state.toggle}
          data={items[state.selectedId]}
          closeView={closeView}
        />
      )}
    </div>
  );
};

export default Transactions;
