import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { reducerCases } from "../../context/constants";
import { useStateProvider } from "../../context/StateReducers";

const Layout = () => {
  const scrollableDivRef = useRef();
  const [toggle, setToggle] = useState(true);
  const navigate = useNavigate();
  const [{ userInfo }, dispatch] = useStateProvider();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/login");
    } else {
      dispatch({
        type: reducerCases.SET_USER_INFO,
        userInfo: JSON.parse(localStorage.getItem("userInfo")),
      });
    }
  }, []);

  const handleScroll = () => {
    const scrollableDiv = scrollableDivRef.current;
    if (
      scrollableDiv &&
      scrollableDiv.scrollTop + scrollableDiv.clientHeight >=
        scrollableDiv.scrollHeight - 10
    ) {
      dispatch({ type: reducerCases.LOAD_MORE, loadMore: true });
    }
  };

  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollableDivRef]);

  return (
    <div className="w-full">
      <div className="flex justify-between h-screen">
        <div
          className={`md:block fixed left-0 top-0 bottom-0 transition-all duration-300 ease-in-out ${
            // toggle ? "absolute w-[100%] z-10 md:w-[5%]" : "hidden w-[16%]"
            toggle
              ? "absolute w-full z-10 md:w-[calc(185px+1vw)]"
              : "hidden md:w-[calc(60px+1vw)]"
          } border-r-2 bg-gray-50 shadow-md rounded-md`}
        >
          <Sidebar toggle={toggle} setToggle={setToggle} />
        </div>
        <div
          className={`w-[100%] ${
            // toggle
            //   ? "md:w-[calc(100%-5%)] md:ml-[5%]"
            //   : "md:w-[calc(100%-16%)] md:ml-[16%]"
            toggle
              ? "md:w-[calc(100%-185px-1vw)] md:ml-[calc(185px+1vw)]"
              : "md:w-[calc(100%-60px-1vw)] md:ml-[calc(60px+1vw)]"
          }`}
        >
          <Header toggle={toggle} setToggle={setToggle} />
          <div
            className="h-[calc(100vh-4rem)] overflow-y-auto"
            id="scrollableDiv"
            ref={scrollableDivRef}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
