import React, { useEffect, useState } from "react";

import { AiOutlineScissor } from "react-icons/ai";

import { BiSolidCoupon } from "react-icons/bi";
import { FaUsers, FaAward } from "react-icons/fa";
import { FiScissors } from "react-icons/fi";
import { RiCalendarCheckFill } from "react-icons/ri";
import { MdStore } from "react-icons/md";
import { GenderPiChartBox, RevenueChart } from "../../components/charts/Chart";
import Appointment from "../../components/Appointment";
import AppointmentChart from "../../components/AppointmentChart";
import { getServices } from "../../services/services.api";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import { toastMessage } from "../../components/toast/toastMessage";
import DashboardRevenueBox from "../../components/DashboardRevenueBox";

const Dashboard = () => {
  const [items, setItems] = useState(null);

  const [{ userInfo, dashboard }, dispatch] = useStateProvider();

  useEffect(() => {
    if (userInfo) {
      callApis();
    }
  }, [userInfo]);

  async function callApis() {
    const query = {};
    const { data } = await getServices(
      `/admin/dashboard`,
      query,
      userInfo?.accessToken
    );

    if (data.success) {
      dispatch({ type: reducerCases.DASHBOARD, dashboard: data?.data });
      setItems([
        {
          name: "Clients",
          count: dashboard?.customers,
          icon: <FaUsers className="text-2xl text-sw-100" />,
        },
        {
          name: "Appointments",
          count: dashboard?.appointments,
          icon: <RiCalendarCheckFill className="text-2xl text-sw-100" />,
        },
        {
          name: "Services",
          count: dashboard?.services,
          icon: <FiScissors className="text-2xl text-sw-100" />,
        },
        {
          name: "Shops",
          count: dashboard?.shops,
          icon: <MdStore className="text-2xl text-sw-100" />,
        },
        {
          name: "Coupons",
          count: dashboard?.coupons,
          icon: <BiSolidCoupon className="text-2xl text-sw-100" />,
        },
        {
          name: "Awards",
          count: dashboard?.awards,
          icon: <FaAward className="text-2xl text-sw-100" />,
        },
      ]);
    } else {
      console.log({ dashboard: data });
      toastMessage(data);
    }
  }

  return (
    <div>
      <div className="md:flex lg:flex md:justify-between lg:justify-between">
        <div className="md:w-3/5 lg:w-3/4">
          <div className="flex flex-col lg:flex-row">
            <div className="flex justify-between w-full lg:w-3/5 xl:w-2/3">
              <div className="grid grid-cols-2 w-full">
                {items &&
                  items.map((item, index) => (
                    <div className="border shadow-md m-1 rounded-md flex items-center space-x-4 pl-2 py-2">
                      <div className="shadow-lg px-2 py-2 rounded-lg">
                        {item.icon}
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm">{item.name}</span>
                        <span className="text-md font-bold">{item.count}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="w-full lg:w-2/5 xl:w-1/3 p-1">
              <div className="flex justify-start w-full space-x-2">
                <div className="w-[100%] shadow-md border rounded-lg p-4">
                  <h3 className="font-medium text-lg">Customers</h3>
                  <GenderPiChartBox />
                </div>
              </div>
            </div>
          </div>

          <div className="p-1 ">
            <AppointmentChart />
          </div>

          <div className="flex p-1">
            <div className="w-full ">
              <DashboardRevenueBox />
            </div>
          </div>
        </div>

        <div className="w-full md:p-1 md:w-2/5 lg:w-1/4 h-available">
          <div className="flex w-full ">
            <div className="w-full">
              <Appointment />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
