import React, { useEffect, useState } from "react";
import bgImg from "../../assets/sample-salon.jpeg";
import { useStateProvider } from "../../context/StateReducers";
import { useNavigate } from "react-router-dom";
import { FiPower } from "react-icons/fi";
import { PiNotePencilLight } from "react-icons/pi";
import ChangePasswordForm from "../../components/form/ChangePasswordForm";
import { reducerCases } from "../../context/constants";

const Settings = () => {
  const [{}, dispatch] = useStateProvider();
  const navigate = useNavigate();
  const [profileState, setProfileState] = useState({
    role: "ADMIN",
    firstName: "vivek",
    lastName: "kumar",
    email: "admin@gmail.com",
    phone: "9121327733",
    isBlock: false,
    status: "unverified",
    createdAt: "2023-09-21T18:00:09.229Z",
    password: "12345678",
    image: bgImg,
  });

  const [passwordFormState, setPasswordFormState] = useState({
    new_pwd: "",
    confirm_new_pwd: "",
  });

  const handleNewPasswordInputChange = (e) => {
    console.log(passwordFormState);
    e.preventDefault();
  };

  const handleConfirmNewPasswordInputChange = (e) => {
    console.log(passwordFormState);
    e.preventDefault();
  };

  const handlePasswordFormSubmit = (e) => {
    if (passwordFormState.new_pwd === passwordFormState.confirm_new_pwd) {
      //simply changes the password for now
      setProfileState({ ...profileState, password: passwordFormState.new_pwd });
    }

    console.log(profileState);
    e.preventDefault();
  };

  useEffect(() => {}, [profileState, passwordFormState]);

  return (
    <div className="p-1">
      <section className="rounded-lg mb-2">
        <div
          className="w-full h-[180px] brightness-80 rounded-lg shadow-md"
          style={{
            backgroundImage: `url(${bgImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="flex justify-end p-5">
            <button
              className="bg-slate-50 text-dark font-medium p-2 rounded-full shadow-lg hover:shadow-2xl border-4 border-red-600"
              onClick={() => {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("userInfo");
                dispatch({
                  type: reducerCases.SET_USER_INFO,
                  userInfo: undefined,
                });
                navigate("/login");
              }}
            >
              <FiPower />
            </button>
          </div>
        </div>

        <div className="p-[8px]">
          <div className="flex justify-start items-start px-2 gap-4 h-[calc(70px+1vw)]">
            <div className="w-36 -translate-y-12 border-4 border-solid border-white  rounded-full bg-slate-300 shadow-md relative">
              <img
                src={"https://picsum.photos/id/669/200/200"}
                height={"100%"}
                loading="lazy"
                className="bg-slate-300 rounded-full"
              />
              <button className="absolute -right-2 bottom-4">
                <PiNotePencilLight className="text-3xl text-sw-100" />
              </button>
            </div>
          </div>

          <div className="p-2 my-4">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2">
              <ChangePasswordForm />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Settings;
