import React, { useEffect, useState } from "react";
import {
  RouterProvider,
  Navigate,
  createBrowserRouter,
} from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";

import Login from "./routes/login/Login.jsx";
import Dashboard from "./routes/dashboard/Dashboard.jsx";
import Layout from "./routes/layout/Layout.jsx";
import Users from "./routes/users/Users.jsx";
import Vendors from "./routes/vendors/Vendors.jsx";
import Transactions from "./routes/transactions/Transactions.jsx";
import Query from "./routes/query/Query.jsx";
import Blogs from "./routes/blogs/Blogs.jsx";
import Gallery from "./routes/gallery/Gallery";
import PackagesList from "./routes/packages/PackagesList.jsx";
import Settings from "./routes/settings/Settings.jsx";
import Services from "./routes/services/Services.jsx";
import ServicesView from "./routes/services/ServicesView.jsx";
import Category from "./routes/categories/Category.jsx";
import CategoryView from "./routes/categories/CategoryView.jsx";
import Requests from "./routes/requests/Requests.jsx";
import PackageView from "./routes/packages/PackageView.jsx";
import Artists from "./routes/artists/Artists.jsx";
import ArtistView from "./routes/artists/ArtistView.jsx";
import Coupons from "./routes/coupons/Coupons.jsx";
import CouponsView from "./routes/coupons/CouponsView.jsx";

import UsersView from "./routes/users/UsersView.jsx";

import Razorpay from "./routes/razorypay/Razorpay.jsx";

import Orders from "./routes/orders/Orders.jsx";
import OrderView from "./routes/orders/OrderView.jsx";
import BlogView from "./routes/blogs/BlogView.jsx";

import VendorView from "./routes/vendors/VendorView.jsx";
import DeleteUsers from "./routes/deleteUsers/DeleteUsers.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <div>Page Not Found!!!</div>,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/category",
        element: <Category />,
      },
      {
        path: "/category/:id",
        element: <CategoryView />,
      },
      {
        path: "/services/:id",
        element: <ServicesView />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/users/:id",
        element: <UsersView />,
      },
      {
        path: "/requests",
        element: <Requests />,
      },
      {
        path: "/vendors",
        element: <Vendors />,
      },
      {
        path: "/vendors/:id",
        element: <VendorView />,
      },
      {
        path: "/artists",
        element: <Artists />,
      },
      {
        path: "/artists/:id",
        element: <ArtistView />,
      },
      {
        path: "/coupons",
        element: <Coupons />,
      },
      {
        path: "/coupons/:id",
        element: <CouponsView />,
      },
      {
        path: "/orders",
        element: <Orders />,
      },
      {
        path: "/orders/:id",
        element: <OrderView />,
      },
      {
        path: "/transactions",
        element: <Transactions />,
      },
      {
        path: "/query",
        element: <Query />,
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/blogs/:id",
        element: <BlogView />,
      },
      {
        path: "/gallery",
        element: <Gallery />,
      },
      {
        path: "/packages",
        element: <PackagesList />,
      },
      {
        path: "/packages/:id",
        element: <PackageView />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/profile",
        element: <Settings />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/delete/users",
    element: <DeleteUsers />,
  },
  {
    path: "/razorpay",
    element: <Razorpay />,
  },
]);

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [loading]);

  return (
    <React.Fragment>
      {loading ? (
        <div className="bg-sw-100 h-screen w-screen flex justify-center items-center">
          <div className="flex items-center space-x-4 cursor-pointer relative">
            <img
              className="w-24 h-24 rounded-3xl hover:shadow-md hover:border hover:border-gray-300"
              src="/sw-brand-logo.png"
              alt="logo"
            />
            <span className="text-8xl text-white font-semibold">
              SalonWallah
            </span>
            <span className="absolute right-0 top-[-20px]">
              <span className="text-lg font-sans text-white/[0.8] font-semibold">
                Admin
              </span>
            </span>
          </div>
        </div>
      ) : (
        <HelmetProvider>
          <RouterProvider router={router} />
        </HelmetProvider>
      )}
    </React.Fragment>
  );
};

export default App;
