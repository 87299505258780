import React, { useEffect, useState } from "react";
import { AiOutlineLogout, AiOutlineSearch } from "react-icons/ai";
import { MdNotificationsActive, MdNotificationsNone } from "react-icons/md";
import { useStateProvider } from "../context/StateReducers";
import { reducerCases } from "../context/constants";
import { useNavigate } from "react-router-dom";
import { IoReorderFour } from "react-icons/io5";

const Header = ({ toggle, setToggle }) => {
  const [{}, dispatch] = useStateProvider();
  const navigate = useNavigate();

  ////////////////////////////////////////////////////////
  /* notifications */

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {}, [notifications]);
  ////////////////////////////////////////////////////////

  return (
    <div className="flex">
      <div className="h-16 w-full border-b-2 flex items-center justify-between px-4 gap-4">
        <div className="flex w-full items-center md:space-x-16">
          <span className="hidden md:block text-[calc(0.75rem+1vw)] font-semibold">
            Admin
          </span>

          <span className="pr-4 md:hidden lg:hidden xl:hidden">
            <IoReorderFour
              onClick={() => setToggle(!toggle)}
              className="text-4xl cursor-pointer text-sw-100"
            />
          </span>

          <div className="h-10 w-full md:w-[500px] relative">
            <span className="absolute top-2 left-2">
              <AiOutlineSearch className="text-2xl" />
            </span>
            <input
              className="w-full h-full rounded-full pl-10 focus:hover:outline-none bg-gray-300"
              type="text"
              placeholder="Search"
            />
          </div>
        </div>

        <div className="p-2 bg-slate-100 rounded-full">
          <span className="text-lg font-semibold cursor-pointer">
            {notifications.length > 0 ? (
              <MdNotificationsActive className="text-sw-100 text-2xl" />
            ) : (
              <MdNotificationsNone className="text-2xl" />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
