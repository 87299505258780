import React, { useEffect, useState } from "react";
import styles from "../services/services.module.css";
import ServicesModel from "../../components/models/ServicesModel";
import { BarChartBox } from "../../components/charts/Chart";
import { FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import QueryView from "./QueryView";
import { UpdateRecord, getRecord } from "../../services/services.api";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import { MOMENT } from "../../utils/dateUtils";
import Pagination from "../../components/form/Pagination";
import { toastMessage } from "../../components/toast/toastMessage";
import { AiOutlineSearch } from "react-icons/ai";

const Query = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [item, setItem] = useState(null);
  const [{ queries, userInfo, refresh }, dispatch] = useStateProvider();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState(null);

  const [state, setState] = useState({
    toggle: false,
    selectedId: null,
  });

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getRecord(
        "/query/list",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.GET_QUERIES,
          queries: data.data.rows,
        });
        setCurrentPage(data.data.currentPage);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  const handleUpdateRead = async (id) => {
    (async function (id) {
      const { data } = await UpdateRecord(
        `/query/update/${id}`,
        {},
        userInfo?.accessToken
      );
      if (data.success) {
        setIsToggle(!isToggle);
      } else {
        toastMessage(data);
      }
    })(id);
  };

  const card = [
    { name: "product1" },
    { name: "product1" },
    { name: "product1" },
  ];

  return (
    <div className="p-1 flex flex-col">
      <div class="shadow-md border rounded-lg mt-2">
        <div className="flex justify-between items-center py-3 px-4 gap-4">
          <div>
            <h1 className="text-lg font-semibold">Queries</h1>
          </div>
          <div className="flex items-center">
            <div className="h-10 w-full relative">
              <span className="absolute top-2 left-2">
                <AiOutlineSearch className="text-2xl" />
              </span>
              <input
                className="w-full h-full rounded-full pl-10 pr-2 focus:hover:outline-none bg-gray-100"
                type="text"
                placeholder="Search"
              />
            </div>
          </div>
        </div>

        <div
          className={`h-[400px] overflow-y-auto cursor-pointer ${styles.editorContainer}`}
        >
          <table class="w-full text-sm text-left text-gray-500">
            <thead class="text-xs text-white uppercase bg-sw-100/[0.8] shadow-md rounded-lg">
              <tr>
                <th scope="col" class="px-6 py-3">
                  S.No
                </th>
                <th scope="col" class="px-6 py-3">
                  Type
                </th>
                <th scope="col" class="px-6 py-3">
                  Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Email
                </th>
                <th scope="col" class="px-6 py-3">
                  Subject
                </th>
                <th scope="col" class="px-6 py-3">
                  Query
                </th>
                <th scope="col" class="px-6 py-3">
                  CreatedAt
                </th>
                <th scope="col" class="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {queries &&
                queries.map((item, index) => (
                  <tr
                    class={`${
                      (index + 1) % 2 == 0 ? "bg-gray-100" : "bg-gray-50"
                    }`}
                    key={index + 1}
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      <span>{index + 1}</span>
                    </th>
                    <td
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      <span>{item?.type}</span>
                    </td>
                    <td class="px-6 py-4">
                      <div className="w-36">
                        <span>{item?.name}</span>
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      <div>
                        <span>{item?.email}</span>
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      <div className="w-48">
                        <span>{item?.subject}</span>
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      <div className="line-clamp-2 w-56">
                        <span>{item?.query}</span>
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      <div className="w-56">
                        <span>{MOMENT(item?.createdAt)}</span>
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      <button
                        onClick={() => {
                          handleUpdateRead(item?.id);
                          setIsToggle(true);
                          setItem(item);
                        }}
                        class="font-medium"
                      >
                        <BsFillEyeFill
                          className={`text-2xl ${
                            item?.isRead ? "text-green-500" : "text-black"
                          }`}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center py-2 px-4">
          <div></div>
          <div className="h-12 w-[300px] relative flex justify-between">
            <div></div>
            <div>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>

      {isToggle && (
        <QueryView
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          data={item}
          setItem={setItem}
        />
      )}
    </div>
  );
};

export default Query;
