import React, { useEffect, useState } from "react";
import { AiFillDelete, AiOutlineSearch } from "react-icons/ai";
import styles from "../services/services.module.css";
import ServicesModel from "../../components/models/ServicesModel";
import CardComponent from "../../components/CardComponent";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import { getRecord } from "../../services/services.api";
import { calculateTime } from "../../utils/dateUtils";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { BsFillEyeFill } from "react-icons/bs";
import Pagination from "../../components/form/Pagination";

const Users = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [{ vendors, userInfo, refresh }, dispatch] = useStateProvider();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getRecord(
        `/admin/users/list`,
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.SET_VENDORS,
          vendors: data.data.rows,
        });
        setCurrentPage(data.data.currentPage);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  return (
    <div className="p-1">
      <div class="shadow-md border rounded-lg">
        <div className="flex justify-between items-center py-3 px-4 gap-4">
          <div>
            <h1 className="text-lg font-semibold">Users</h1>
          </div>
          <div className="flex items-center">
            <div className="h-10 w-full relative">
              <span className="absolute top-2 left-2">
                <AiOutlineSearch className="text-2xl" />
              </span>
              <input
                className="w-full h-full rounded-full pl-10 pr-2 focus:hover:outline-none bg-gray-100"
                type="text"
                placeholder="Search"
              />
            </div>
            {/* <div className="pl-4">
              <button
                onClick={() => setIsToggle(!isToggle)}
                className="w-[150px] bg-green-400 py-2 rounded-full text-white font-semibold"
              >
                Add
              </button>
            </div> */}
          </div>
        </div>

        <div
          className={`h-[400px] overflow-y-auto cursor-pointer ${styles.editorContainer}`}
        >
          <table class="w-full text-sm text-left text-gray-500">
            <thead class="text-xs text-white uppercase bg-sw-100/[0.8]">
              <tr>
                <th scope="col" class="px-6 py-3">
                  S.No
                </th>
                <th scope="col" class="px-6 py-3">
                  User Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Role
                </th>
                <th scope="col" class="px-6 py-3">
                  Email
                </th>
                <th scope="col" class="px-6 py-3">
                  Phone
                </th>
                <th scope="col" class="px-6 py-3">
                  createdAt
                </th>
                <th scope="col" class="px-6 py-3">
                  status
                </th>
                <th scope="col" class="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {vendors &&
                vendors.map((item, index) => (
                  <tr
                    class={`${
                      (index + 1) % 2 == 0 ? "bg-gray-100" : "bg-gray-50"
                    }`}
                    key={index + 1}
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      <span>{index + 1}</span>
                    </th>
                    <td class="px-6 py-4">
                      {item?.firstName} {item?.lastName}
                    </td>
                    <td class="px-6 py-4">{item?.role}</td>
                    <td class="px-6 py-4">{item?.email}</td>
                    <td class="px-6 py-4">{item?.phone}</td>
                    <td class="px-6 py-4">{calculateTime(item?.createdAt)}</td>
                    <td class="px-6 py-4">
                      <span className="text-green-700 font-semibold capitalize">
                        {item?.status}
                      </span>
                    </td>
                    <td className="px-6 py- space-x-4">
                      <div className="flex space-x-2">
                        <button
                          // onClick={() => editHandleClick(item?.id)}
                          class="font-medium"
                        >
                          <FiEdit className="text-md" />
                        </button>
                        <Link to={`/users/${item?.id}`} className="font-medium">
                          <BsFillEyeFill className="text-md" />
                        </Link>
                        <button
                          // onClick={() => handleDelete(item?.id)}
                          className="font-medium text-sw-100"
                        >
                          <AiFillDelete className="text-md" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center py-2 px-4">
          <div></div>
          <div className="h-12 w-[300px] relative flex justify-between">
            <div></div>
            <div>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
      {isToggle && (
        <ServicesModel isToggle={isToggle} setIsToggle={setIsToggle} />
      )}
    </div>
  );
};

export default Users;
