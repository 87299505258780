import React from "react";
import { BarChartBox } from "./charts/Chart";

const AppointmentChart = () => {
  return (
    <div className="p-4 rounded-lg shadow-md border">
      <h3 className="font-medium">Appointments</h3>
      <BarChartBox />
    </div>
  );
};

export default AppointmentChart;
