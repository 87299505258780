import React, { useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { MdDescription, MdVerified } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getServices } from "../../services/services.api";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import { toastMessage } from "../../components/toast/toastMessage";
import { calculateTime } from "../../utils/dateUtils";
import styles from "./blogs.module.css";
import { IoTimeSharp } from "react-icons/io5";
import { useState } from "react";

const BlogView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [coverImage, setCoverImage] = useState(null);
  const [{ userInfo, blog }, dispatch] = useStateProvider();

  useEffect(() => {
    if (userInfo) {
      callApi();
    }
  }, [userInfo]);

  const callApi = async () => {
    const query = {};
    const { data } = await getServices(
      `/admin/blogs/view/${id}`,
      query,
      userInfo?.accessToken
    );

    if (data.success) {
      dispatch({ type: reducerCases.VIEW_BLOGS, blog: data?.data });
    } else {
      toastMessage(data);
    }
  };

  return (
    <div className="px-2 py-2">
      <div className="flex items-center justify-start space-x-4 border-b-2 py-3">
        <span onClick={() => navigate(-1)}>
          <BiArrowBack className="text-lg cursor-pointer font-bold" />
        </span>
        <p className="text-xs font-medium">
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            Services
          </span>{" "}
          {"/"}{" "}
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            {blog?.title.slice(0, 20)}
          </span>
        </p>
      </div>

      <div className="my-6">
        <div className="flex flex-wrap justify-between space-x-4">
          <div className="w-full md:flex-1">
            <div className="h-80 rounded-lg shadow-md ring-1 ring-gray-100">
              <img
                className="h-full w-full rounded-lg"
                src={coverImage ? coverImage : blog?.coverImage}
                alt="icon"
              />
            </div>

            <div
              className={`px-2 py-2 overflow-x-auto ${styles.ImageScrollerContainer} `}
            >
              <div className="flex space-x-2 cursor-pointer">
                {blog &&
                  blog?.gallery &&
                  blog?.gallery?.map((url) => (
                    <div className="h-16 w-20 flex-shrink-0">
                      <img
                        onClick={() => setCoverImage(url)}
                        className="h-full w-full rounded-lg"
                        src={url}
                        alt="icon"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="w-full md:flex-1">
            <div className="flex flex-col space-y-2">
              <div className="flex items-center space-x-2">
                {blog?.status === "active" && (
                  <>
                    <MdVerified className="text-green-600 font-bold text-lg" />
                    <span className="font-semibold text-gray-400">
                      Verified
                    </span>
                  </>
                )}
              </div>
              <p className="text-base font-semibold capitalize">
                {blog?.title}
              </p>
              <p className="text-sm text-gray-400 font-semibold">
                <span>{blog?.subTitle}</span>
                <span>, {calculateTime(blog?.createdAt)}</span>
              </p>
            </div>

            <div className="w-full">
              <div
                className="py-3"
                dangerouslySetInnerHTML={{ __html: blog?.html }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogView;
