import React from "react";
import IMG1 from "../../assets/screen2.png";
import IMG2 from "../../assets/screen1.png";
import { FaUser } from "react-icons/fa";
import { BiSolidLockAlt } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { IoMdPhonePortrait } from "react-icons/io";
import { toastMessage } from "../../components/toast/toastMessage";
import { deleteRecord } from "../../services/services.api";

const DeleteUsers = () => {
  const { register, handleSubmit } = useForm();
  const handleLogin = (payload) => {
    console.log({ payload });
    handleDelete(payload.phone);
  };

  const handleDelete = async (phone) => {
    let params = { phone };
    const { data } = await deleteRecord(`/user/delete/web`, params);
    if (data.success) {
      toastMessage(data);
    } else {
      toastMessage(data);
    }
  };

  return (
    <div className="h-screen overflow-hidden">
      <div className="w-full h-full flex justify-center items-center">
        <div>
          <div className="h-[200px]">
            <img src={IMG1} className="h-full" alt="logo" />
          </div>
          <div>
            <h1 className="font-medium text-lg py-2 border-b">
              Please enter your phone below.
            </h1>
            <form
              className="py-4"
              onSubmit={handleSubmit((data) => handleLogin(data))}
            >
              <div className="space-y-2 relative">
                <span className="text-lg font-semibold">Phone</span>
                <span className="absolute top-10 left-2">
                  <IoMdPhonePortrait className="text-lg" />
                </span>
                <input
                  {...register("phone", { required: true })}
                  className="w-full py-2 focus:outline-none bg-gray-50 pl-8 rounded-md shadow-lg text-md hover:bg-gray-100"
                  type="text"
                  placeholder="Enter phone number ?"
                />
              </div>

              <div className="space-y-2 flex justify-center py-4">
                <button
                  className="bg-sw-100 text-white font-semibold px-6 py-1 rounded-lg "
                  type="submit"
                >
                  Delete User
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUsers;
