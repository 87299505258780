import React, { useEffect, useRef, useState } from "react";
import { FaHome } from "react-icons/fa";
import { HiMiniUsers } from "react-icons/hi2";
import { GrGallery } from "react-icons/gr";
import { BiTransfer } from "react-icons/bi";
import { IoMdImages } from "react-icons/io";
import {
  MdCategory,
  MdEmail,
  MdMiscellaneousServices,
  MdOutlineRequestQuote,
  MdWarehouse,
  MdWorkHistory,
} from "react-icons/md";
import { BsPencilSquare } from "react-icons/bs";
import { AiFillGift, AiFillSetting, AiOutlineClose } from "react-icons/ai";
import { IoReorderFour } from "react-icons/io5";
import { SiFreelancer } from "react-icons/si";
import { Link, NavLink } from "react-router-dom";
import { useStateProvider } from "../context/StateReducers";

import styles from "./form/scrollbar.module.css";
import { BiSolidCoupon } from "react-icons/bi";

const Sidebar = ({ toggle, setToggle }) => {
  const [{ userInfo }] = useStateProvider();

  const [sidebarList, setSidebarList] = useState([
    {
      name: "Home",
      to: "/dashboard",
      icon: <FaHome className="text-lg icon" />,
      active: false,
    },
    {
      name: "Services",
      to: "/services",
      icon: <MdMiscellaneousServices className="text-lg icon" />,
      active: false,
    },
    {
      name: "Category",
      to: "/category",
      icon: <MdCategory className="text-lg icon" />,
      active: false,
    },
    {
      name: "Packages",
      to: "/packages",
      icon: <AiFillGift className="text-lg icon" />,
      active: false,
    },
    {
      name: "Users",
      to: "/users",
      icon: <HiMiniUsers className="text-lg icon" />,
      active: false,
    },
    {
      name: "Artists",
      to: "/artists",
      icon: <SiFreelancer className="text-lg icon" />,
      active: false,
    },
    {
      name: "Vendors",
      to: "/vendors",
      icon: <MdWarehouse className="text-lg icon" />,
      active: false,
    },
    {
      name: "Coupons",
      to: "/coupons",
      icon: <BiSolidCoupon className="text-lg icon" />,
      active: false,
    },
    {
      name: "Requests",
      to: "/requests",
      icon: <MdOutlineRequestQuote className="text-lg icon" />,
      active: false,
    },
    {
      name: "Orders",
      to: "/orders",
      icon: <MdWorkHistory className="text-lg icon" />,
      active: false,
    },
    {
      name: "Transactions",
      to: "/transactions",
      icon: <BiTransfer className="text-lg icon" />,
      active: false,
    },
    {
      name: "Query",
      to: "/query",
      icon: <MdEmail className="text-lg icon" />,
      active: false,
    },
    {
      name: "Blogs",
      to: "/blogs",
      icon: <BsPencilSquare className="text-lg icon" />,
      active: false,
    },
    {
      name: "Gallery",
      to: "/gallery",
      icon: <IoMdImages className="text-lg icon" />,
      active: false,
    },
    {
      name: "Settings",
      to: "/settings",
      icon: <AiFillSetting className="text-lg icon" />,
      active: false,
    },
  ]);

  return (
    <div className="w-full flex flex-col justify-between h-screen">
      <div className={`w-full`}>
        <div
          className={`flex h-16 ${
            toggle ? "justify-between" : "justify-center"
          } items-center px-4 py-3 font-semibold border-b-2`}
        >
          {toggle && (
            <div className="flex items-center space-x-1">
              <img
                className="w-8 h-8 md:hidden lg:block rounded-3xl hover:shadow-md hover:border hover:border-gray-300"
                src="/sw-brand-logo.png"
                alt="logo"
              />
              <span className="font-semibold text-sw-100 text-[calc(0.5rem+0.75vw)] ml-2">
                SalonWallah
              </span>
            </div>
          )}
          <span>
            <button onClick={() => setToggle(!toggle)}>
              {toggle ? (
                <AiOutlineClose className="text-2xl cursor-pointer text-sw-100" />
              ) : (
                <IoReorderFour className="text-2xl cursor-pointer text-sw-100" />
              )}
            </button>
          </span>
        </div>

        <div
          className={`${
            toggle ? "px-2 py-4" : "py-4"
          } overflow-y-auto h-[78vh] overflow-x-none`}
        >
          <ul className="space-y-4 sidebar-list">
            {sidebarList &&
              sidebarList.map((item, index) => (
                <li
                  key={index + 1}
                  className={`flex md:items-center cursor-pointer ${
                    toggle ? "px-2" : "justify-center"
                  }`}
                >
                  <NavLink
                    to={item.to}
                    className={`flex items-center space-x-2 ${
                      toggle
                        ? "pl-2 w-full"
                        : "justify-center w-[80%] rounded-xl"
                    }`}
                  >
                    <span>{item.icon}</span>

                    {toggle && (
                      <span className="text-[calc(0.5rem+0.5vw)] font-normal">
                        {item.name}
                      </span>
                    )}
                  </NavLink>
                </li>
              ))}
          </ul>
        </div>

        <div className="w-full flex justify-start items-baseline">
          <Link
            to="/profile"
            className={`flex items-center space-x-4 py-4 px-2 ${
              toggle ? "justify-start" : "justify-center"
            }`}
          >
            <div>
              <img
                className="w-12 h-12 md:w-8 md:h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-12 rounded-full"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRGD6R6Mj8gLH_Bb_aO1NnZC-eqYFx1XJ3K1d1oFCx42K5fXl8B3LSCVD4vrv-RpLQ_Kg&usqp=CAU"
                alt="alt"
              />
            </div>

            {toggle && (
              <div className="flex flex-col">
                <span className="font-semibold capitalize text-[calc(0.5rem+0.5vw)]">
                  {userInfo?.firstName} {userInfo?.lastName}
                </span>
                <span className="font-semibold text-[calc(0.4rem+0.5vw)] text-gray-400">
                  Admin
                </span>
              </div>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
