import React, { useEffect, useState } from "react";
import { AiFillDelete, AiOutlineSearch } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsFillEyeFill } from "react-icons/bs";
import styles from "./services.module.css";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import {
  deleteCategories,
  getCategories,
  getServices,
} from "../../services/services.api";
import Pagination from "../../components/form/Pagination";
import CategoryModel from "./CategoryModel";
import { Link } from "react-router-dom";
import { calculateTime } from "../../utils/dateUtils";
import { toastMessage } from "../../components/toast/toastMessage";

const Category = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [{ categories, userInfo, refresh }, dispatch] = useStateProvider();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    !isToggle && setCategory(null);
  }, [isToggle]);

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getCategories(
        "/admin/services/category/list/admin",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.GET_CATEGORIES,
          categories: data.data.rows,
        });
        setCurrentPage(data.data.currentPage);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  const handleDelete = (category) => {
    (async function (category) {
      let params = {};
      const { data } = await deleteCategories(
        `/admin/services/category/delete/${category}`,
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
        toastMessage(data);
      }
    })(category);
  };

  const editHandleClick = (categoryId) => {
    (async function (categoryId) {
      const query = {};
      const { data } = await getServices(
        `/admin/services/category/view/${categoryId}/admin`,
        query,
        userInfo?.accessToken
      );

      if (data.success) {
        setIsToggle(!isToggle);
        setCategory(data?.data);
      } else {
        toastMessage(data);
      }
    })(categoryId);
  };

  return (
    <div className="p-1">
      <div class="shadow-md border rounded-lg">
        <div className="flex justify-between items-center py-3 px-4 gap-4">
          <div>
            <h1 className="text-lg font-semibold">
              {refresh ? "Loading..." : "Category"}
            </h1>
          </div>
          <div className="flex items-center">
            <div className="h-10 w-full relative">
              <span className="absolute top-2 left-2">
                <AiOutlineSearch className="text-2xl" />
              </span>
              <input
                className="w-full h-full rounded-full pl-10 pr-2 focus:hover:outline-none bg-gray-100"
                type="text"
                placeholder="Search"
              />
            </div>
            <div className="pl-4">
              <button
                onClick={() => setIsToggle(!isToggle)}
                className="w-fit bg-green-400 text-sm px-5 py-2 rounded-full text-white font-semibold"
              >
                Add
              </button>
            </div>
          </div>
        </div>

        <div
          className={`h-[400px] overflow-y-auto cursor-pointer ${styles.editorContainer} relative`}
        >
          <table className="w-full text-sm text-left text-gray-500 relative">
            <thead className="text-xs text-white uppercase bg-sw-100/[0.8]">
              <tr>
                <th scope="col" className="px-6 py-3">
                  S.No
                </th>
                <th scope="col" className="px-6  py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  description
                </th>

                <th scope="col" className="px-6 py-3">
                  CreatedAt
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>

            {/* {isLoading ? (
              <Loading />
            ) : ( */}
            <tbody>
              {categories &&
                categories?.map((item, index) => (
                  <tr
                    class={`capitalize ${
                      (index + 1) % 2 == 0 ? "bg-gray-100" : "bg-gray-50"
                    }`}
                    key={index + 1}
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {index + 1}
                    </th>
                    <td className="px-6 py-4">{item.type}</td>
                    <td className="px-6 py-4">{item.name}</td>
                    <td className="px-6 py-4">
                      <p className="line-clamp-2 justify-between capitalize">
                        {item.description}
                      </p>
                    </td>
                    <td className="px-6 py-4">
                      {calculateTime(item.createdAt)}
                    </td>
                    <td className="px-6 py-4 text-green-700 first-letter:uppercase">
                      {item.status}
                    </td>

                    <td className="px-6 py- space-x-4">
                      <div className="flex space-x-2">
                        <button
                          onClick={() => editHandleClick(item?.id)}
                          class="font-medium"
                        >
                          <FiEdit className="text-md" />
                        </button>
                        <Link
                          to={`/category/${item?.id}`}
                          className="font-medium"
                        >
                          <BsFillEyeFill className="text-md" />
                        </Link>
                        <button
                          onClick={() => handleDelete(item?.id)}
                          className="font-medium text-sw-100"
                        >
                          <AiFillDelete className="text-md" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* )} */}
          </table>
        </div>

        <div className="flex justify-between items-center py-2 px-4">
          <div></div>
          <div className="h-12 w-[300px] relative flex justify-between">
            <div></div>
            <div>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>

      {isToggle && (
        <CategoryModel
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          category={category}
        />
      )}
    </div>
  );
};

export default Category;
