export const reducerCases = {
  SET_USER_INFO: "SET_USER_INFO",
  GET_SERVICES: "GET_SERVICES",
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_ORDERS: "GET_ORDERS",
  VIEW_CATEGORY: "VIEW_CATEGORY",
  VIEW_PACKAGE: "VIEW_PACKAGE",
  VIEW_COUPON: "VIEW_COUPON",
  DASHBOARD: "DASHBOARD",
  GET_WITHDRAWAL_REQUESTS: "GET_WITHDRAWAL_REQUESTS",
  SET_REFRESH: "SET_REFRESH",
  SET_PACKAGES: "SET_PACKAGES",
  SET_VENDORS: "SET_VENDORS",
  GET_COUPONS: "GET_COUPONS",
  GET_BLOGS: "GET_BLOGS",
  VIEW_BLOGS: "VIEW_BLOGS",
  GET_GELLARY: "GET_GELLARY",
  VIEW_GELLARY: "VIEW_GELLARY",
  LOAD_MORE: "LOAD_MORE",
  UPCOMING_APPOINTMENT: "UPCOMING_APPOINTMENT",
  GET_QUERIES: "GET_QUERIES",
};
