import API from "../config/api";

export const CREATE_SERVICES = async (url, payload, token) => {
  try {
    const response = API.post(url, payload, {
      Authorization: `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    return { data: error?.response?.data };
  }
};

export const AddRecord = async (url, payload, token) => {
  try {
    const response = await API.post(url, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return { data: error?.response?.data };
  }
};

export const getRecord = async (url, params, token) => {
  try {
    // console.log({ token });
    const response = API.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return { data: error?.response?.data };
  }
};

export const UpdateRecord = async (url, payload, token) => {
  try {
    const response = await API.patch(url, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return { data: error?.response?.data };
  }
};

export const deleteRecord = async (url, params, token) => {
  try {
    const response = API.delete(url, {
      params,
      data: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return { data: error?.response?.data };
  }
};

export const getServices = async (url, params, token) => {
  try {
    // console.log({ token });
    const response = API.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return { data: error?.response?.data };
  }
};

export const getCategories = async (url, params, token) => {
  try {
    const response = API.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log(error?.response);
    return { data: error?.response?.data };
  }
};

export const deleteCategories = async (url, params, token) => {
  try {
    const response = API.delete(url, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return { data: error?.response?.data };
  }
};
