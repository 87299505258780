import React, { useState } from "react";
import GalleryModel from "./GalleryModel";
import { useStateProvider } from "../../context/StateReducers";
import { useEffect } from "react";
import { reducerCases } from "../../context/constants";
import { deleteRecord, getRecord } from "../../services/services.api";
import { toastMessage } from "../../components/toast/toastMessage";
import { AiOutlineFullscreen, AiOutlineMinusCircle } from "react-icons/ai";
import { FullScreen } from "../../components/form/ImageViewer";

const Gallery = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [isFullScreen, setIsfullScreen] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [{ gallery, userInfo, refresh, loadMore }, dispatch] =
    useStateProvider();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState([
    { name: "Branding" },
    { name: "Design" },
    { name: "Development" },
    { name: "HairStyle" },
  ]);

  useEffect(() => {
    callApis();
  }, [userInfo, refresh, loadMore]);

  async function callApis() {
    if (userInfo) {
      setLoading(true);
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getRecord(
        "/admin/gallery/list",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        if (loadMore) {
          setLimit((prev) => prev + 5);
        }
        dispatch({
          type: reducerCases.GET_GELLARY,
          gallery: data.data.rows,
        });
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
        dispatch({ type: reducerCases.LOAD_MORE, loadMore: false });
      }
    }
  }

  const handleDelete = (id) => {
    console.log({ id });
    (async function (id) {
      let params = {};
      const { data } = await deleteRecord(
        `admin/gallery/delete/${id}`,
        params,
        userInfo?.accessToken
      );
      if (data.success) {
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
        toastMessage(data);
      }
    })(id);
  };

  return (
    <div className="p-1">
      <div className="px-2 py-2">
        <div className="border-b-2 pb-2">
          <div className="flex justify-center">
            <div className="text-center">
              <h1 className="uppercase text-2xl font-semibold text-sw-100">
                SalonWallah Gallery
              </h1>
              <span className="capitalize text-sm font-semibold text-gray-400">
                we are a style brand
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="space-x-2">
            <button className="px-3 border border-sw-100 rounded-lg text-white bg-sw-100">
              All
            </button>
            {types &&
              types.map((type, index) => (
                <button className="px-3 border border-sw-100 rounded-lg text-gray-400 active:bg-sw-100">
                  {type.name}
                </button>
              ))}
          </div>
          <div>
            <button
              onClick={() => setIsToggle(!isToggle)}
              className="bg-sw-100 px-4 py-1 rounded-lg cursor-pointer text-white font-semibold uppercase"
            >
              Add Photo
            </button>
          </div>
        </div>

        <div className="py-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
            {gallery &&
              gallery.map((item, index) => (
                <div
                  key={index + 1}
                  className="relative cursor-pointer hover:border hover:border-sw-100 h-[268px]"
                >
                  <img
                    src={item.files[0]}
                    alt="file"
                    className="w-full h-full"
                  />
                  <div className="absolute top-0 right-0 w-full h-full flex justify-end items-baseline pt-3">
                    <div className="flex space-x-2">
                      <button onClick={() => handleDelete(item?.id)}>
                        <span>
                          <AiOutlineMinusCircle className="text-2xl text-sw-100 hover:text-3xl" />
                        </span>
                      </button>
                      <button
                        onClick={() => {
                          setImageURL(item.files[0]);
                          setIsfullScreen(true);
                        }}
                      >
                        <span>
                          <AiOutlineFullscreen className="text-2xl text-white hover:text-3xl" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {/* {loading && (
            <div>
              <span>Loading...</span>
            </div>
          )} */}
        </div>
      </div>

      {isToggle && (
        <GalleryModel isToggle={isToggle} setIsToggle={setIsToggle} />
      )}

      {isFullScreen && (
        <FullScreen
          isFullScreen={isFullScreen}
          setIsfullScreen={setIsfullScreen}
          imageURL={imageURL}
        />
      )}
    </div>
  );
};

export default Gallery;
