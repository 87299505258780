import React from "react";
import { FaFileInvoice, FaTimes } from "react-icons/fa";
import { MdCallToAction, MdDateRange, MdMail } from "react-icons/md";
import { calculateTime } from "../../utils/dateUtils";
import { IoPricetags } from "react-icons/io5";
import { BsFillPersonFill } from "react-icons/bs";

const TransactionView = ({ isToggle, closeView, data }) => {
  let transactiondata = Object(data);

  return (
    <div className="fixed inset-0 flex items-center justify-center backdrop-blur-sm z-20 w-full border border-slate-400">
      <div className={`w-[100%] h-full bg-gray-100/[0.6] pt-12`}>
        <div className="w-full h-full flex justify-center items-start">
          <div className="w-[96%] sm:w-[80%] md:w-[70%] lg:w-[45%] h-fit shadow-md border text-gray-900 bg-white rounded-lg px-2 py-2 relative">
            <FaTimes
              onClick={() => closeView()}
              className="absolute right-5 top-5 text-2xl cursor-pointer text-sw-100"
            />
            <section className="mt-10 p-1">
              <div className="pb-1 flex items-center space-x-2">
                <span>
                  <MdDateRange className="text-lg" />
                </span>
                <span className="text-gray-700 font-semibold text-lg">
                  Date :
                </span>
                <span className="text-gray-500 capitalize text-md">
                  {calculateTime(transactiondata?.date)}
                </span>
              </div>

              <div className="pb-1 flex items-center space-x-2">
                <span>
                  <IoPricetags className="text-lg" />
                </span>
                <span className="text-gray-700 font-semibold text-lg">
                  Amount :
                </span>
                <span className="text-gray-500 capitalize text-md">
                  Rs {transactiondata?.total}
                </span>
              </div>

              <div className="pb-1 flex items-center space-x-2">
                <span>
                  <BsFillPersonFill className="text-lg" />
                </span>
                <span className="text-gray-700 font-semibold text-lg">
                  Client :
                </span>
                <span className="text-gray-500 capitalize text-md">
                  {transactiondata?.client}
                </span>
              </div>

              <div className="pb-1 flex items-center space-x-2">
                <span>
                  <FaFileInvoice className="text-lg" />
                </span>
                <span className="text-gray-700 font-semibold text-lg">
                  Invoice Number :
                </span>
                <span className="text-gray-500 capitalize text-md">
                  {transactiondata?.invoice}
                </span>
              </div>

              <div className="pb-1 flex items-center space-x-2">
                <span>
                  <MdCallToAction className="text-lg" />
                </span>
                <span className="text-gray-700 font-semibold text-lg">
                  Status :
                </span>
                <span className="text-gray-500 capitalize text-md">
                  {transactiondata?.status}
                </span>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionView;
