import axios from "axios";

export const AUTH_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYiLCJwaG9uZSI6Ijk3MTg1NDExMTQiLCJyb2xlIjoiVkVORE9SIiwiaWF0IjoxNjk3OTkzNTU4LCJleHAiOjE2OTgwNzk5NTh9.T_bGCR5i9pYZpvrUruWrdk_pdDw4RHfAInz_9SLUVVY";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default API;
