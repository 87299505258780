import React from "react";
import { FaTimes } from "react-icons/fa";
import { MdMail } from "react-icons/md";

const QueryView = ({ isToggle, setIsToggle, data, setItem }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center backdrop-blur-sm z-20 w-full border border-slate-400">
      <div className={`w-[100%] h-full bg-gray-100/[0.6]`}>
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-[96%] sm:w-[88%] md:w-[80%] lg:w-[65%] h-fit shadow-md border text-gray-900  bg-white rounded-lg px-2 py-2 relative">
            <FaTimes
              onClick={() => {
                setIsToggle(!isToggle);
                setItem(null);
              }}
              className="absolute right-5 top-5 text-2xl cursor-pointer text-sw-100"
            />
            <section className="mt-10 p-1">
              <div className="flex justify-start md:justify-between flex-col md:flex-row">
                <div>
                  <div className="flex items-center justify-center space-x-1">
                    <span>
                      <MdMail />
                    </span>
                    <span> {data?.email}</span>
                  </div>
                  <div className="pl-4">
                    <p className="text-sm text-gray-700">{data?.createdAt}</p>
                  </div>
                </div>
              </div>

              <div>
                <p className="text-lg font-semibold py-4">
                  {data?.type} - {data?.subject}
                </p>
              </div>

              <div className="pb-4">
                <p
                  className="text-sm "
                  dangerouslySetInnerHTML={{ __html: data.query }}
                ></p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueryView;
