import React from "react";
import { reducerCases } from "../../context/constants";
import { useStateProvider } from "../../context/StateReducers";

const Pagination = ({ totalPages, currentPage, setPage }) => {
  const [{}, dispatch] = useStateProvider();
  return (
    <ul className="flex items-center h-12 text-sm">
      <li>
        <button
          onClick={() => {
            dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
            setPage(currentPage === 1 ? 1 : currentPage - 1);
          }}
          className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:text-gray-70"
        >
          <span className="sr-only">Previous</span>
          <svg
            className="w-2.5 h-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
        </button>
      </li>

      {totalPages &&
        totalPages?.map((item, index) => (
          <li key={index + 1}>
            <button
              onClick={() => {
                dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
                setPage(item);
              }}
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:text-gray-700 dark:hover:text-gray-700"
            >
              {item}
            </button>
          </li>
        ))}

      <li>
        <button
          onClick={() => {
            dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
            setPage(
              currentPage === totalPages.length
                ? totalPages.length
                : currentPage + 1
            );
          }}
          className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:text-gray-700 dark:hover:text-gray-700"
        >
          <span className="sr-only">Next</span>
          <svg
            className="w-2.5 h-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
        </button>
      </li>
    </ul>
  );
};

export default Pagination;
