import React from "react";
import { AUTH_TOKEN, BASE_URL } from "../../config/api";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const Razorpay = () => {
  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razropay failed to load!!");
      return;
    }

    const data = await fetch(`${BASE_URL}/user/orders/checkout/packages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AUTH_TOKEN}`,
      },
      body: JSON.stringify({
        type: "shop",
        paymentType: "online",
        shopId: 5,
        packageId: 25,
        serviceProviderId: 7,
        bookingDate: "10/25/2023",
        bookingTime: "10:30 AM",
        qty: 1,
        totalPrice: 299,
      }),
    }).then((t) => t.json());

    console.log(data.data);

    const options = {
      key: "rzp_test_QnwqipInppdTkf",
      amount: data.data.amount,
      currency: data.data.currency,
      name: "Salonwallah.com",
      description: "Test Transaction",
      image:
        "https://play-lh.googleusercontent.com/Cr4ShjqYLDvwHFxZQjtyYg9JJ4h62KgTCFR2fT_kWQzKvmH4lyFEw80PPzsUDkUwJmU",
      order_id: data.data.id,
      notes: { address: "Razorpay Corporate Office" },
      theme: { color: "#3399cc" },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div>
        <button
          onClick={displayRazorpay}
          className="shadow-lg px-6 py-2 ring-1 rounded-lg font-bold"
        >
          Book Now
        </button>
      </div>
    </div>
  );
};

export default Razorpay;
