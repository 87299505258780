import React from "react";

import cnt from "../assets/constant.json";

import { FaCoins, FaMoneyBill } from "react-icons/fa";

import { RevenueChart } from "./charts/Chart";

const data = {
  today: 1000,
  monthly: 5000,
};

function DashboardRevenueBox() {
  return (
    <div className="flex flex-col shadow-md p-4 w-full border rounded-lg justify-start h-available">
      <h3 className="font-medium">Revenue</h3>
      <div
        className={`grid grid-cols-2 grid-flow-dense h-fit pt-4 w-full gap-4`}
      >
        <div className="flex flex-row items-center">
          <div className="p-1 rounded-lg shadow-md">
            <FaCoins
              style={{
                borderRadius: "10px",
                color: cnt.color.primary,
              }}
              size={"calc(1.5rem + 1vw)"}
            />
          </div>

          <div className="flex flex-col pl-2">
            <h4 className="font-medium text-sm">Today</h4>
            <h4 className="font-semibold text-md">Rs {data.today}</h4>
          </div>
        </div>

        <div className="flex flex-row items-center">
          <div className="p-1 rounded-lg shadow-md">
            <FaMoneyBill
              style={{
                borderRadius: "10px",
                color: cnt.color.primary,
              }}
              size={"calc(1.5rem + 1vw)"}
            />
          </div>

          <div className="flex flex-col pl-2">
            <h4 className="font-medium text-sm">Monthly</h4>
            <h4 className="font-semibold text-md">Rs {data.monthly}</h4>
          </div>
        </div>
      </div>
      <br />
      <RevenueChart />
    </div>
  );
}

export default DashboardRevenueBox;
