import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { reducerCases } from "../context/constants";
import { useStateProvider } from "../context/StateReducers";
import { useEffect } from "react";
import { getRecord } from "../services/services.api";
import { MOMENT } from "../utils/dateUtils";

const Appointment = () => {
  const [{ upcoming_appointment, userInfo, refresh }, dispatch] =
    useStateProvider();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [items, setItems] = useState([
    { name: "haircut" },
    { name: "haircut" },
    { name: "haircut" },
    { name: "haircut" },
    { name: "haircut" },
    { name: "haircut" },
    { name: "haircut" },
    { name: "haircut" },
    { name: "haircut" },
    { name: "haircut" },
  ]);

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getRecord(
        "/user/orders/list?isFinished=false",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.UPCOMING_APPOINTMENT,
          upcoming_appointment: data.data.rows,
        });
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  return (
    <div className="border shadow-md px-3 py-4 rounded-lg">
      <div>
        <h3 className="text-lg font-medium pb-4">Upcoming Appointment</h3>
      </div>
      <div className="pb-4">
        <span className="text-sm text-gray-400">Today</span>
      </div>
      <div className="pb-5 overflow-hidden h-[78vh] overflow-y-auto">
        <ul className="space-y-2">
          {upcoming_appointment &&
            upcoming_appointment.map((item, index) => (
              <li>
                <div className="flex justify-between border shadow-md px-2 py-2 rounded-lg cursor-pointer">
                  <div className="flex items-center space-x-2">
                    <div>
                      <img
                        className="h-12 w-12 rounded-full"
                        src={
                          item?.User?.avatar
                            ? item?.User?.avatar
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRGD6R6Mj8gLH_Bb_aO1NnZC-eqYFx1XJ3K1d1oFCx42K5fXl8B3LSCVD4vrv-RpLQ_Kg&usqp=CAU"
                        }
                        alt="logs"
                      />
                    </div>
                    <div className="flex flex-col">
                      <span className="text-md">
                        {item?.User?.firstName
                          ? item?.User?.firstName
                          : "Guest"}
                        {item?.User?.lastName}
                      </span>
                      <span className="text-sm text-gray-500">
                        {MOMENT(item?.createdAt)}
                      </span>
                      <span className="text-sm font-semibold capitalize text-gray-600">
                        {item?.name}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col justify-between">
                    <div className="relative">
                      <button className="right-0 absolute bg-sw-100 rounded-md px-2">
                        <BsThreeDots className="text-white" />
                      </button>
                    </div>
                    <span className="text-sm">Rs. {item?.totalPrice}</span>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Appointment;
