import React, { useEffect, useState } from "react";
import { AiFillDelete, AiOutlineSearch } from "react-icons/ai";
import styles from "../services/services.module.css";
import ServicesModel from "../../components/models/ServicesModel";
import CardComponent from "../../components/CardComponent";
import { useStateProvider } from "../../context/StateReducers";
import { getRecord } from "../../services/services.api";
import { reducerCases } from "../../context/constants";
import Pagination from "../../components/form/Pagination";
import { BsFillEyeFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { MOMENT } from "../../utils/dateUtils";

const Orders = () => {
  const [{ orders, userInfo, refresh }, dispatch] = useStateProvider();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getRecord(
        "/user/orders/list",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.GET_ORDERS,
          orders: data.data.rows,
        });
        setCurrentPage(data.data.currentPage);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  const [isToggle, setIsToggle] = useState(false);

  return (
    <div className="p-1">
      <div class="shadow-md border rounded-lg mt-2">
        <div className="flex justify-between items-center py-3 px-4 gap-4">
          <div>
            <h1 className="text-lg font-semibold">Orders</h1>
          </div>
          <div className="flex items-center">
            <div className="h-10 w-full relative">
              <span className="absolute top-2 left-2">
                <AiOutlineSearch className="text-2xl" />
              </span>
              <input
                className="w-full h-full rounded-full pl-10 pr-2 focus:hover:outline-none bg-gray-100"
                type="text"
                placeholder="Search"
              />
            </div>
          </div>
        </div>

        <div
          className={`h-[400px] overflow-y-auto cursor-pointer ${styles.editorContainer}`}
        >
          <table class="w-full text-sm text-left text-gray-500">
            <thead class="text-xs text-white uppercase bg-sw-100/[0.8]">
              <tr>
                <th scope="col" class="px-6 py-3">
                  S.No
                </th>
                <th scope="col" class="px-6 py-3">
                  Type
                </th>
                <th scope="col" class="px-6 py-3">
                  Username
                </th>
                <th scope="col" class="px-6 py-3">
                  qty
                </th>
                <th scope="col" class="px-6 py-3">
                  Amount
                </th>
                <th scope="col" class="px-6 py-3">
                  bookingDateTime
                </th>
                <th scope="col" class="px-6 py-3">
                  isFinished
                </th>
                <th scope="col" class="px-6 py-3">
                  createdAt
                </th>
                <th scope="col" class="px-6 py-3">
                  status
                </th>
                <th scope="col" class="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {orders &&
                orders.map((item, index) => (
                  <tr
                    class={`${
                      (index + 1) % 2 == 0 ? "bg-gray-100" : "bg-gray-50"
                    }`}
                    key={index + 1}
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {index + 1}
                    </th>
                    <td class="px-6 py-4">{item?.type}</td>
                    <td class="px-6 py-4">
                      <div className="w-36">
                        <span>
                          {item?.User?.firstName} {item?.User?.firstName}
                        </span>
                      </div>
                    </td>
                    <td class="px-6 py-4">{item?.qty}</td>
                    <td class="px-6 py-4">
                      <div>{item?.totalPrice}</div>
                    </td>
                    <td class="px-6 py-4">
                      <div className="capitalize w-48">
                        {MOMENT(item?.bookingDateTime)}
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      {item?.isFinished ? (
                        <div>
                          <span className="text-green-700 font-semibold">
                            Finished
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span className="text-sw-100 font-semibold">
                            Processig
                          </span>
                        </div>
                      )}
                    </td>
                    <td class="px-6 py-4">
                      <div className="w-48">{MOMENT(item?.createdAt)}</div>
                    </td>
                    <td class="px-6 py-4">
                      <div>{item?.status}</div>
                    </td>
                    <td class="px-6 py-6">
                      <div className="flex space-x-2">
                        <Link
                          to={`/orders/${item?.id}`}
                          class="font-medium space-x-2 flex items-center"
                        >
                          <BsFillEyeFill className="text-md" />
                          <span>View</span>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center py-2 px-4">
          <div></div>
          <div className="h-12 flex justify-between">
            <div></div>
            <div>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
      {isToggle && (
        <ServicesModel isToggle={isToggle} setIsToggle={setIsToggle} />
      )}
    </div>
  );
};

export default Orders;
