import React, { useEffect, useState } from "react";
import { AiFillDelete, AiOutlineSearch } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsFillEyeFill } from "react-icons/bs";
import styles from "./services.module.css";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import {
  AddRecord,
  deleteRecord,
  getRecord,
  getServices,
} from "../../services/services.api";
import ServicesModel from "../../components/models/ServicesModel";
import Pagination from "../../components/form/Pagination";
import { calculateTime } from "../../utils/dateUtils";
import { Link } from "react-router-dom";
import { toastMessage } from "../../components/toast/toastMessage";
const Services = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [{ services, userInfo, refresh }, dispatch] = useStateProvider();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [editData, setEditData] = useState(null);
  const [code, setCode] = useState("");

  useEffect(() => {
    !isToggle && setEditData(null);
  }, [isToggle]);

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getServices(
        "/admin/services/list/admin",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.GET_SERVICES,
          services: data.data.rows,
        });
        setCurrentPage(data.data.currentPage);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  const handleDelete = (serviceId) => {
    (async function (serviceId) {
      let params = {};
      const { data } = await deleteRecord(
        `/admin/services/delete/${serviceId}`,
        params,
        userInfo?.accessToken
      );
      if (data.success) {
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
        toastMessage(data);
      }
    })(serviceId);
  };

  const editHandleClick = (serviceId) => {
    (async function (serviceId) {
      const query = {};
      const { data } = await getRecord(
        `/admin/services/view/${serviceId}/admin`,
        query,
        userInfo?.accessToken
      );

      if (data.success) {
        setIsToggle(!isToggle);
        setEditData(data?.data);
      } else {
        toastMessage(data);
      }
    })(serviceId);
  };

  const applyCouponCode = (serviceId) => {
    (async function (serviceId) {
      const { data } = await AddRecord(
        `/coupons/apply/${serviceId}/services`,
        {
          code: code,
        },
        userInfo?.accessToken
      );

      if (data.success) {
        toastMessage(data);
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
      } else {
        toastMessage(data);
      }
    })(serviceId);
  };

  return (
    <div className="p-1">
      <div class="shadow-md border rounded-lg">
        <div className="flex justify-between items-center py-3 px-4 gap-4">
          <div>
            <h1 className="text-lg font-semibold">Services</h1>
          </div>
          <div className="flex items-center">
            <div className="h-10 w-full relative">
              <span className="absolute top-2 left-2">
                <AiOutlineSearch className="text-2xl" />
              </span>
              <input
                className="w-full h-full rounded-full pl-10 pr-2 focus:hover:outline-none bg-gray-100"
                type="text"
                placeholder="Search"
              />
            </div>
            <div className="pl-4">
              <button
                onClick={() => setIsToggle(!isToggle)}
                className="w-fit bg-green-400 text-sm px-5 py-2 rounded-full text-white font-semibold"
              >
                Add
              </button>
            </div>
          </div>
        </div>

        <div
          className={`h-[400px] overflow-y-auto cursor-pointer ${styles.editorContainer}`}
        >
          <table class="w-full text-sm text-left text-gray-500 relative">
            <thead class="text-xs text-white uppercase bg-sw-100/[0.8]">
              <tr>
                <th scope="col" class="px-6 py-3">
                  S.No
                </th>
                <th scope="col" class="px-6 py-3">
                  Type
                </th>
                <th scope="col" class="px-6 py-3">
                  Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Category
                </th>
                <th scope="col" class="px-6 py-3">
                  Service Type
                </th>
                <th scope="col" class="px-6 py-3">
                  description
                </th>
                <th scope="col" class="px-6 py-3">
                  Price
                </th>
                <th scope="col" class="px-6 py-3">
                  CreatedAt
                </th>
                <th scope="col" class="px-6 py-3">
                  Status
                </th>
                <th scope="col" class="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>

            {/* {isLoading ? (
              <Loading />
            ) : ( */}
            <tbody>
              {services &&
                services?.map((item, index) => (
                  <tr
                    class={`${
                      (index + 1) % 2 == 0 ? "bg-gray-100" : "bg-gray-50"
                    }`}
                    key={index + 1}
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {index + 1}
                    </th>
                    <td class="px-6 py-4 capitalize">{item?.type}</td>
                    <td class="px-6 py-4 capitalize">{item?.name}</td>
                    <td class="px-6 py-4 capitalize">{item?.category?.name}</td>
                    <td class="px-6 py-4 capitalize">{item?.serviceType}</td>
                    <td class="px-6 py-4">
                      <p className="line-clamp-2 justify-between capitalize">
                        {item.description}
                      </p>
                    </td>
                    <td class="px-6 py-4">
                      <div className="space-y-2">
                        <span className="font-semibold">Rs.{item.price}</span>
                        {/* <div className="flex justify-center space-x-1">
                          {item.isApplyCoupon ? (
                            <>
                              <span>{item.code}</span>
                              <span className="bg-green-500 text-white uppercase px-2 rounded-sm font-semibold">
                                Applied
                              </span>
                            </>
                          ) : (
                            <>
                              <input
                                type="text"
                                className="focus:outline-none w-20 py-1 px-2 rounded-sm bg-gray-200"
                                placeholder="Code"
                                onChange={(e) => setCode(e.target.value)}
                              />
                              <button
                                disabled={code === "" ? true : false}
                                onClick={() => applyCouponCode(item.id)}
                                className={`bg-sw-100 cursor-pointer ${
                                  code === "" && "bg-sw-100/[0.6]"
                                } text-white uppercase px-2 rounded-sm font-semibold`}
                              >
                                Apply
                              </button>
                            </>
                          )}
                        </div> */}
                      </div>
                    </td>
                    <td class="px-6 py-4 capitalize">
                      {calculateTime(item.createdAt)}
                    </td>
                    <td class="px-6 py-4 capitalize">{item.status}</td>

                    <td class="px-6 py-6">
                      <div className="flex space-x-2">
                        <button
                          onClick={() => editHandleClick(item?.id)}
                          class="font-medium"
                        >
                          <FiEdit className="text-md" />
                        </button>
                        <Link to={`/services/${item?.id}`} class="font-medium">
                          <BsFillEyeFill className="text-md" />
                        </Link>
                        <button
                          onClick={() => handleDelete(item?.id)}
                          class="font-medium text-sw-100"
                        >
                          <AiFillDelete className="text-md" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* )} */}
          </table>
        </div>

        <div className="flex justify-between items-center py-2 px-4">
          <div></div>
          <div className="h-12 w-[300px] relative flex justify-between">
            <div></div>
            <div>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
      {isToggle && (
        <ServicesModel
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          editData={editData}
        />
      )}
    </div>
  );
};

export default Services;
